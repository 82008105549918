//
// -- Imports
//

import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box, ListPage, Search, Spinner, Table, Tabs, ToolHeader } from "@procore/core-react";

import useFetch from "../../hooks/useFetch";
import Skilljar from "../../domain";

//
// -- Helper Functions
//

function filterBySearch(list: Skilljar.CourseOverview[], term: string) {
  return list.filter((course) => course.title.toLowerCase().indexOf(term.toLowerCase()) >= 0);
}

//
// -- Courses List Route
//

export default function List() {
  let { company_id } = useParams();

  const [search, setSearch] = useState("");
  const history = useHistory();

  const courses = {
    company: useFetch<Skilljar.CourseOverview[]>(`/api/${company_id}/courses`),
    procore: useFetch<Skilljar.CourseOverview[]>(`/api/${company_id}/courses`, {
      params: { type: "procore" },
    }),
  };

  return (
    <div>
      <ListPage>
        <ListPage.Main>
          <ListPage.ToolHeader>
            <ToolHeader>
              <ToolHeader.Title>Certification Analytics</ToolHeader.Title>
              <ToolHeader.Tabs>
                <Tabs.Tab active>
                  <Tabs.Link>By Course</Tabs.Link>
                </Tabs.Tab>
                <Tabs.Tab>
                  <Tabs.Link onClick={()=> history.push(`/viewer/${company_id}/students`)}>By Student</Tabs.Link>
                </Tabs.Tab>
              </ToolHeader.Tabs>
            </ToolHeader>
          </ListPage.ToolHeader>
          <ListPage.ContentControls>
            <ListPage.Control variant="search">
              <Search
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                placeholder="Search"
                value={search}
              />
            </ListPage.Control>
          </ListPage.ContentControls>
          <ListPage.Body>
            {(() => {
              if (courses.company.type == "loading" || courses.procore.type == "loading") {
                return (
                  <Spinner loading={true}>
                    <div style={{ height: "300px" }} />
                  </Spinner>
                );
              }

              if (courses.company.type == "ready" && courses.procore.type == "ready") {
                return (
                  <Box marginBottom="xl">
                    <Box marginBottom="xl">
                      <CourseTable title="Company Courses">
                        {filterBySearch(courses.company.data, search).map((course) => (
                          <CourseRow course={course} onClick={() => {
                            history.push(`/viewer/${company_id}/courses/${course.course_id}`)
                          }}/>
                        ))}
                      </CourseTable>
                    </Box>

                    <CourseTable title="Procore Courses">
                      {filterBySearch(courses.procore.data, search).map((course) => (
                        <CourseRow course={course} onClick={() => {
                          history.push(`/viewer/${company_id}/courses/${course.course_id}`)
                        }}/>
                      ))}
                    </CourseTable>
                  </Box>
                );
              }

              if (courses.company.type == "errored" || courses.procore.type == "errored") {
                return <div>"Error"</div>;
              }

              return null;
            })()}
          </ListPage.Body>
        </ListPage.Main>
      </ListPage>
    </div>
  );
}

//
// -- Course Table
//

const CourseTable: React.FunctionComponent<{ title: string }> = ({ title, children }) => (
  <Table.Container>
    <Table>
      <Table.Header>
        <Table.HeaderRow>
          <Table.HeaderCell>{title}</Table.HeaderCell>
          <Table.HeaderCell snugfit>Enrolled</Table.HeaderCell>
          <Table.HeaderCell snugfit>Completed</Table.HeaderCell>
        </Table.HeaderRow>
      </Table.Header>
      <Table.Body>{children}</Table.Body>
    </Table>
  </Table.Container>
);

//
// -- Individual Course Row
//

const CourseRow = ({ course, onClick }: { course: Skilljar.CourseOverview, onClick?: () => void }) => (
  <Table.BodyRow key={course.course_id}>
    <Table.BodyCell>
      <Table.LinkCell
        href="#"
        onClick={(e) => {
          e.preventDefault();
          onClick && onClick()
        }}
      >
        {course.title}
      </Table.LinkCell>
    </Table.BodyCell>
    <Table.BodyCell>
      <Table.PercentCell>{course.enrollment_count}</Table.PercentCell>
    </Table.BodyCell>
    <Table.BodyCell>
      <Table.PercentCell>{course.complete_count}</Table.PercentCell>
    </Table.BodyCell>
  </Table.BodyRow>
);
