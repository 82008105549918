import { useState, useEffect } from "react";
import axios, { AxiosRequestConfig } from "axios";

type RequestState<T> =
  | { type: "loading" }
  | { type: "ready"; data: T }
  | { type: "errored" };

export default function useFetch<T>(url: string, config: AxiosRequestConfig = {}) {
  const [state, setState] = useState<RequestState<T>>({ type: "loading" });

  useEffect(
    () => {
      async function run() {
        try {
          const response = await axios.request<T>({
            method: "get",
            url: url,
            headers: {
              "Content-Type": "application/json",
            },
            ...config,
          });

          setState({ type: "ready", data: response.data });
        } catch {
          setState({ type: "errored" });
        }
      }

      run();
    },
    [],
  );

  return state;
}
