import courses from "./courses";
import students from "./students";
import New from "./sessions/New";

export default {
  courses,
  students,
  sessions: {
    New: New,
  },
};
