import * as React from "react";

import { Box, FlexList, Font } from "@procore/core-react";

export default (props: { complete: number }) => (
  <FlexList size="xs" alignItems="center">
    <Box style={{ width: "100px", backgroundColor: "#E6E6E6" }}>
      <div
        style={{
          width: `${props.complete}px`,
          backgroundColor: "#3397FF",
          textAlign: "center",
          height: "12px",
        }}
      ></div>
    </Box>
    <Font weight="regular">{props.complete}%</Font>
  </FlexList>
);
