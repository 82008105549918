//
// -- Imports
//

import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  Box,
  Breadcrumbs,
  ListPage,
  Search,
  Spinner,
  Table,
  ToolHeader,
} from "@procore/core-react";

import { formattedTime } from "../../utils/time";

import Skilljar from "../../domain";
import Progress from "../../components/Progress";
import useFetch from "../../hooks/useFetch";

//
// -- Student Show Route
//

export default function Show() {
  let { company_id, id } = useParams();
  const [search, setSearch] = useState("");
  const history = useHistory();
  const student = useFetch<Skilljar.StudentProgress>(`/api/${company_id}/students/${id}`);

  return (
    <ListPage>
      <ListPage.Main>
        <ListPage.ToolHeader>
          <ListPage.Banner>
            <Breadcrumbs>
              <Breadcrumbs.Crumb
                onClick={() => {
                  history.push(`/viewer/${company_id}/students`);
                }}
              >
                Students
              </Breadcrumbs.Crumb>
              <Breadcrumbs.Crumb>Student Analytics</Breadcrumbs.Crumb>
            </Breadcrumbs>
          </ListPage.Banner>
          <ToolHeader>
            <ToolHeader.Title>{student.type == "ready" ? student.data.name : ""}</ToolHeader.Title>
          </ToolHeader>
        </ListPage.ToolHeader>
        <ListPage.ContentControls>
          <ListPage.Control variant="search">
            <Search
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder="Search"
              value={search}
            />
          </ListPage.Control>
        </ListPage.ContentControls>
        <ListPage.Body>
          {(() => {
            switch (student.type) {
              case "loading":
                return (
                  <Spinner loading={true}>
                    <div style={{ height: "300px" }} />
                  </Spinner>
                );

              case "errored":
                return <div>Errored</div>;

              case "ready":
                return (
                  <Box marginBottom="xl">
                    <Box marginBottom="xl">
                      <Table.Container>
                        <Table>
                          <Table.Header>
                            <Table.HeaderRow>
                              <Table.HeaderCell>Course Name</Table.HeaderCell>
                              <Table.HeaderCell>% Complete</Table.HeaderCell>
                              <Table.HeaderCell>Enrolled </Table.HeaderCell>
                              <Table.HeaderCell>Completed</Table.HeaderCell>
                              <Table.HeaderCell>Latest Activity</Table.HeaderCell>
                            </Table.HeaderRow>
                          </Table.Header>
                          <Table.Body>
                            {student.data.courses
                              .filter(
                                (course) =>
                                  course.title.toLowerCase().indexOf(search.toLowerCase()) >= 0,
                              )
                              .map((progress) => (
                                <Course
                                  progress={progress}
                                  key={progress.course_id}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    history.push(
                                      `/viewer/${company_id}/courses/${progress.course_id}`,
                                    );
                                  }}
                                />
                              ))}
                          </Table.Body>
                        </Table>
                      </Table.Container>
                    </Box>
                  </Box>
                );
            }
          })()}
        </ListPage.Body>
      </ListPage.Main>
    </ListPage>
  );
}

//
// -- Course Row
//

const Course = ({
  onClick,
  progress,
}: {
  onClick: (e: React.MouseEvent<any>) => void;
  progress: Skilljar.StudentCourseProgress;
}) => (
  <Table.BodyRow key={progress.course_id}>
    <Table.BodyCell>
      <Table.LinkCell href="#" onClick={onClick}>
        {progress.title}
      </Table.LinkCell>
    </Table.BodyCell>
    <Table.BodyCell>
      <Table.TextCell>
        <Progress complete={Math.floor((progress.completed / progress.lesson_count) * 100)} />
      </Table.TextCell>
    </Table.BodyCell>
    <Table.BodyCell>
      <Table.TextCell>{formattedTime(progress.enrolled_at)}</Table.TextCell>
    </Table.BodyCell>
    <Table.BodyCell>
      <Table.TextCell>{formattedTime(progress.completed_at)}</Table.TextCell>
    </Table.BodyCell>
    <Table.BodyCell>
      <Table.TextCell>{formattedTime(progress.latest_activity_at)}</Table.TextCell>
    </Table.BodyCell>
  </Table.BodyRow>
);
