export const formattedTime = (time?: string | null) => {
  if (!time) {
    return "--";
  }

  const options = { year: "numeric", month: "short", day: "numeric" };
  const date = new Date(Date.parse(time));

  return date.toLocaleDateString(undefined, options);
};
