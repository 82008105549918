//
// -- Imports
//

import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box, ListPage, Search, Spinner, Table, Tabs, ToolHeader } from "@procore/core-react";

import useFetch from "../../hooks/useFetch";
import Skilljar from "../../domain";

//
// -- Helper Functions
//

function filterBySearch(list: Skilljar.Student[], term: string) {
  return list.filter(
    (student) =>
      student.name.toLowerCase().indexOf(term.toLowerCase()) >= 0 ||
      student.email.toLowerCase().indexOf(term.toLowerCase()) >= 0,
  );
}

//
// -- Students Index Route
//

export default function List() {
  let { company_id } = useParams();
  const [search, setSearch] = useState("");
  const history = useHistory();
  const students = useFetch<Skilljar.Student[]>(`/api/${company_id}/students`);

  return (
    <ListPage>
      <ListPage.Main>
        <ListPage.ToolHeader>
          <ToolHeader>
            <ToolHeader.Title>Certification Analytics</ToolHeader.Title>
            <ToolHeader.Tabs>
              <Tabs.Tab>
                <Tabs.Link onClick={() => history.push(`/viewer/${company_id}/courses`)}>
                  By Course
                </Tabs.Link>
              </Tabs.Tab>
              <Tabs.Tab active>
                <Tabs.Link onClick={() => history.push(`/viewer/${company_id}/students`)}>
                  By Student
                </Tabs.Link>
              </Tabs.Tab>
            </ToolHeader.Tabs>
          </ToolHeader>
        </ListPage.ToolHeader>
        <ListPage.ContentControls>
          <ListPage.Control variant="search">
            <Search
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder="Search"
              value={search}
            />
          </ListPage.Control>
        </ListPage.ContentControls>
        <ListPage.Body>
          {(() => {
            switch (students.type) {
              case "loading":
                return (
                  <Spinner loading={true}>
                    <div style={{ height: "300px" }} />
                  </Spinner>
                );

              case "errored":
                return <div>Errored</div>;

              case "ready":
                return (
                  <Box marginBottom="xl">
                    <Box marginBottom="xl">
                      <Table.Container>
                        <Table>
                          <Table.Header>
                            <Table.HeaderRow>
                              <Table.HeaderCell>Name</Table.HeaderCell>
                              <Table.HeaderCell>Email</Table.HeaderCell>
                              <Table.HeaderCell snugfit>Enrolled</Table.HeaderCell>
                              <Table.HeaderCell snugfit>Completed</Table.HeaderCell>
                            </Table.HeaderRow>
                          </Table.Header>
                          <Table.Body>
                            {filterBySearch(students.data, search).map((student) => (
                              <Table.BodyRow key={student.id}>
                                <Table.BodyCell>
                                  <Table.LinkCell
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      history.push(`/viewer/${company_id}/students/${student.id}`);
                                    }}
                                  >
                                    {student.name}
                                  </Table.LinkCell>
                                </Table.BodyCell>
                                <Table.BodyCell>
                                  <Table.TextCell>{student.email}</Table.TextCell>
                                </Table.BodyCell>
                                <Table.BodyCell>
                                  <Table.PercentCell>{student.enrollment_count}</Table.PercentCell>
                                </Table.BodyCell>
                                <Table.BodyCell>
                                  <Table.PercentCell>{student.completed_count}</Table.PercentCell>
                                </Table.BodyCell>
                              </Table.BodyRow>
                            ))}
                          </Table.Body>
                        </Table>
                      </Table.Container>
                    </Box>
                  </Box>
                );
            }
          })()}
        </ListPage.Body>
      </ListPage.Main>
    </ListPage>
  );
}
