import React from "react";
import { Spinner } from "@procore/core-react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import routes from "./routes";
import { TC } from "./domain";
import useFetch from "./hooks/useFetch";

export default function App() {
  const config = useFetch<TC.Config>("/api/config");

  switch (config.type) {
    case "loading":
      return (
        <Spinner loading={true}>
          <div style={{ height: "300px" }} />
        </Spinner>
      );

    case "ready":
      if (config.data.signed_in) {
        return (
          <Router>
            <Route path="/viewer/:company_id/courses" exact component={routes.courses.List} />
            <Route path="/viewer/:company_id/courses/:id" exact component={routes.courses.Show} />
            <Route path="/viewer/:company_id/students/" exact component={routes.students.List} />
            <Route path="/viewer/:company_id/students/:id" exact component={routes.students.Show} />
          </Router>
        );
      } else {
        return (
          <Router>
            <Route path="/">
              <routes.sessions.New authPath={config.data.auth_path} />
            </Route>
          </Router>
        );
      }

    case "errored":
      return <div>Something went wrong</div>;
  }
}
