//
// -- Imports
//

import React from "react";
import { ParentContext } from "@procore/iframe-helpers";
import { Button, Box, Header } from "@procore/core-react";

//
// -- Signin Route
//

export default function New(props: { authPath: string }) {
  const signin = () => {
    const context = new ParentContext();
    context.authentication.authenticate({
      url: props.authPath,
      onSuccess: (_data: {}) => {
        window.location.reload();
      },
      onFailure: () => {
        console.log("Failed");
      },
    });
  };

  return (
    <Box margin="xl auto" style={{ width: "300px", marginTop: "64px" }}>
      <Header type="h1">Please confirm your account to continue.</Header>

      <Box margin="xl none">
        <Button block size="lg" onClick={signin}>
          Click to confirm
        </Button>
      </Box>
    </Box>
  );
}
