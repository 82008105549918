//
// -- Imports
//

import React, { useState } from "react";
import {
  Box,
  Breadcrumbs,
  EmptyState,
  ListPage,
  Search,
  Spinner,
  Table,
  ToolHeader,
} from "@procore/core-react";

import Skilljar from "../../domain";

import { useHistory, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";

import Progress from "../../components/Progress";
import { formattedTime } from "../../utils/time";

//
// -- Helper Functions
//

function filterBySearch(list: Skilljar.CourseProgress[], term: string) {
  return list.filter((progress) => {
    return (
      progress.first_name.toLowerCase().indexOf(term.toLowerCase()) >= 0 ||
      progress.last_name.toLowerCase().indexOf(term.toLowerCase()) >= 0 ||
      progress.email.toLowerCase().indexOf(term.toLowerCase()) >= 0
    );
  });
}

//
// -- Courses Index Route
//
export default function Show() {
  let { company_id, id } = useParams();
  const [search, setSearch] = useState("");
  const history = useHistory();

  const course = useFetch<Skilljar.Course>(`/api/${company_id}/courses/${id}`);

  return (
    <ListPage>
      <ListPage.Main>
        <ListPage.ToolHeader>
          <ListPage.Banner>
            <Breadcrumbs>
              <Breadcrumbs.Crumb onClick={() => history.push(`/viewer/${company_id}/courses`)}>
                Courses
              </Breadcrumbs.Crumb>
              <Breadcrumbs.Crumb>Course Analytics</Breadcrumbs.Crumb>
            </Breadcrumbs>
          </ListPage.Banner>
          <ToolHeader>
            <ToolHeader.Title>{course.type == "ready" ? course.data.title : ""}</ToolHeader.Title>
          </ToolHeader>
        </ListPage.ToolHeader>
        <ListPage.ContentControls>
          <ListPage.Control variant="search">
            <Search
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder="Search"
              value={search}
            />
          </ListPage.Control>
        </ListPage.ContentControls>
        <ListPage.Body>
          {(() => {
            switch (course.type) {
              case "errored":
                return <div></div>;

              case "loading":
                return (
                  <Spinner loading={true}>
                    <div style={{ height: "300px" }} />
                  </Spinner>
                );

              case "ready":
                const lesson_count = course.data.lesson_count;

                return (
                  <Table.Container>
                    <Table>
                      <Table.Header>
                        <Table.HeaderRow>
                          <Table.HeaderCell>Name</Table.HeaderCell>
                          <Table.HeaderCell>Email</Table.HeaderCell>
                          <Table.HeaderCell>% Complete</Table.HeaderCell>
                          <Table.HeaderCell>Registered</Table.HeaderCell>
                          <Table.HeaderCell>Completed</Table.HeaderCell>
                          <Table.HeaderCell>Latest Activity</Table.HeaderCell>
                        </Table.HeaderRow>
                      </Table.Header>
                      <Table.Body>
                        {filterBySearch(course.data.progress, search).map((progress) => (
                          <StudentProgress
                            progress={progress}
                            key={progress.student_id}
                            lessonCount={lesson_count}
                            onClick={(e) => {
                              e.preventDefault();
                              history.push(`/viewer/${company_id}/students/${progress.student_id}`);
                            }}
                          />
                        ))}
                      </Table.Body>
                    </Table>
                    {course.data.progress.length == 0 && (
                      <Box paddingBottom="xl">
                        <EmptyState>
                          <EmptyState.Title>No one has signed up for this course.</EmptyState.Title>
                          <EmptyState.Description>
                            As your employees sign up and take courses, their progress will be shown
                            here.
                          </EmptyState.Description>
                        </EmptyState>
                      </Box>
                    )}
                  </Table.Container>
                );
            }
          })()}
        </ListPage.Body>
      </ListPage.Main>
    </ListPage>
  );
}

//
// -- Student Progress Row
//

const StudentProgress = ({
  onClick,
  progress,
  lessonCount,
}: {
  onClick: (e: React.MouseEvent<any>) => void;
  progress: Skilljar.CourseProgress;
  lessonCount: number;
}) => (
  <Table.BodyRow key={progress.email}>
    <Table.BodyCell>
      <Table.LinkCell href="#" onClick={onClick}>
        {progress.first_name} {progress.last_name}
      </Table.LinkCell>
    </Table.BodyCell>
    <Table.BodyCell>
      <Table.TextCell>{progress.email}</Table.TextCell>
    </Table.BodyCell>
    <Table.BodyCell>
      <Table.TextCell>
        <Progress complete={Math.floor((progress.completed / lessonCount) * 100)} />
      </Table.TextCell>
    </Table.BodyCell>
    <Table.BodyCell>
      <Table.TextCell>{formattedTime(progress.enrolled_at)}</Table.TextCell>
    </Table.BodyCell>
    <Table.BodyCell>
      <Table.TextCell>{formattedTime(progress.completed_at)}</Table.TextCell>
    </Table.BodyCell>
    <Table.BodyCell>
      <Table.TextCell>{formattedTime(progress.latest_activity_at)}</Table.TextCell>
    </Table.BodyCell>
  </Table.BodyRow>
);
