import * as React from 'react';
import * as ReactDom from 'react-dom';

import App from "./App";

const mount = document.getElementById('mount');

function render() {
  ReactDom.render(<App />, mount);
}

render();

if (module.hot) {
  module.hot.accept('./App', render);
}
